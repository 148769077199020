import type { GatsbyBrowser } from "gatsby";

import { setupReveal } from "./src/assets/js/scroll-reveal";

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  document.documentElement.classList.remove("no-js");
  document.documentElement.classList.add("js");

  window.addEventListener("load", () => {
    document.body.classList.add("is-loaded");
  });

  // Moved out of React for performance
  const onScroll = () => {
    const classList = document.body.classList;
    window.scrollY > 0
      ? classList.add("scrolled")
      : classList.remove("scrolled");
  };
  window.addEventListener("scroll", onScroll);
  onScroll();
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  setupReveal();

  // Hack to disable blog nav transitions when programatically scrolling after route updates
  document.body.classList.add("disable-blog-header-transitions");
  setTimeout(() => {
    document.body.classList.remove("disable-blog-header-transitions");
  }, 50);
};

const getBlogPathProperties = (path) => {
  // Match "/blog/" and "/blog/2/"
  const results = /^\/blog\/((?<page>\d+)\/)?$/.exec(path);

  if (results?.groups) {
    return {
      page: results.groups.page || 1,
      category: null,
    };
  }

  // Match "/blog/topic/churn-management/" and "/blog/topic/churn-management/2/"
  const categoryResults =
    /^\/blog\/topic\/(?<category>[\w-]+)\/((?<page>\d+)\/)?$/.exec(path);

  if (categoryResults?.groups) {
    return {
      page: categoryResults.groups.page || 1,
      category: categoryResults.groups.category,
    };
  }

  return null;
};

// Returns boolean whether scroll was handled here
const getBlogScrollPosition = (
  prevRouterProps,
  routerProps,
  prevScrollPosition
) => {
  const prev = getBlogPathProperties(prevRouterProps);
  const curr = getBlogPathProperties(routerProps);

  if (!prev || !curr) {
    return false;
  }

  // If the category changed, maintain scroll position (requires a hack)
  if (prev.category !== curr.category) {
    let y = prevScrollPosition[1];
    // Hack adjustment needed because of the nav behavior
    if (y > 40) {
      y += 96;
    }
    return [prevScrollPosition[0], y];
  }

  // If the page changed, scroll to the category box
  if (prev.page !== curr.page) {
    const bar = document.getElementById("blog-category-bar");
    if (bar) {
      const rect = bar.getBoundingClientRect();
      const foo = [window.scrollX, rect.y + window.scrollY - 86];
      return foo;
    }

    return false;
  }

  return false;
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  // Special cases for certain route changes on the blog
  if (prevRouterProps?.location) {
    const prevScrollPosition = getSavedScrollPosition(prevRouterProps.location);

    const blogScrollPosition = getBlogScrollPosition(
      prevRouterProps.location.pathname,
      routerProps.location.pathname,
      prevScrollPosition
    );

    if (blogScrollPosition) {
      // https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
      // return blogScrollPosition;
      // ^ Returning an array _should_ work, but it doesn't. So instead, a dirty hack:
      (window as any)._blogScrollTo = blogScrollPosition;
    }
  }

  return true;
};
